import React from 'react';
import Layout from '../components/Layout';
import { SocialMedialLinks } from '../components/SocialMediaLinks';
import styles from './scholarship.module.css';

class scholarshipPage extends React.Component {
  render() {
    return (
      <Layout pageTitle="Scholarship">
        <div className={styles.scholarshipPage}>
          <h3>
            সৌরভ স্মৃতি বৃত্তি
          </h3>
          <div>
          <span>
          লুব্ধক’০৭ ফাউন্ডেশনের অন্যতম উদ্দেশ্য সমাজের দরিদ্র এবং অবহেলিত মানুষের দিকে সহযোগিতার  হাত বাড়িয়ে তাঁদের অবস্থার উন্নতির মাধ্যমে দেশকে সামনে এগিয়ে নেয়া। 
          একটা পরিবারে যদি একজন মানুষ স্বাবলম্বী হয়, তাহলে তার মাধ্যমে পরিবার, সমাজ তথা দেশই একটা দীর্ঘমেয়াদী ফল পায়। 
          এ লক্ষ্যে লুব্ধক’০৭ ফাউন্ডেশন চালু করতে যাচ্ছে আর্থিক ভাবে সংকটাপন্ন মেধাবী ছাত্রছাত্রীদের জন্য “সৌরভ স্মৃতি বৃত্তি”। 
          ২০১২ সালে আমাদের মাঝে থেকে হঠাৎ হারিয়ে যাওয়া বন্ধু সৈয়দ আবু আসরার সৌরভের স্মৃতিকে অম্লান রাখতে তার নামে এই বৃত্তির নামকরণ করা হয়েছে। 
          এই বৃত্তি পেয়ে যে সব ছাত্রছাত্রী উপকৃত হবে, তাদের মধ্যেই আমরা খুঁজে নেব আমাদের বন্ধুকে। 
</span>
<br />
<br />
<span>
যে কোন বিশ্ববিদ্যলয়ের স্নাতকে অধ্যয়নরত বা সদ্য ভর্তি হওয়া যে কোন ছাত্র ছাত্রী বৃত্তির জন্য আবেদন করতে পারবে। বৃত্তির মেয়াদকাল ১ বছর। ১ বছর পর বৃত্তি পুনর্বিবেচনার জন্য পুনরায় আবেদন 
বাধ্যতামূলক। বৃত্তির জন্যে তিন ভাবে আবেদন করা যেতে পারেঃ 
<ul>
<li> অনলাইনে গুগল ফর্ম পূরণ করে। <a href="https://docs.google.com/forms/d/1LNZKsYLUg1hnOQe1ZoPh0iUPK1A761hjwSx-G1t4puc/" className={styles.adminEmail}> 
              ফর্ম পূরণ করতে এখানে ক্লিক করুন
              </a> </li>
<li> ওয়েবসাইট থেকে ফর্মের পিডিএফ ডাউনলোড করে সেটা পূরণ করে ইমেইলের মাধ্যমে পাঠিয়ে ।  ইমেইলের বিষয় (সাবজেক্ট)  হিসেবে <b>Shourov Memorial Scholarship 2021</b> অথবা <b>সৌরভ স্মৃতি বৃত্তি ২০২১</b> থাকতে হবে।  ইমেইলঃ  
              <a
                href="mailto:lubdhok.foundation@gmail.com"
                className={styles.adminEmail}
              >
                lubdhok.foundation@gmail.com
              </a>  
 </li>
<li> ওয়েবসাইট থেকে ফর্মের পিডিএফ ডাউনলোড করে সেটা পূরণ করে লুব্ধক'০৭ ফাউন্ডেশনের ঠিকানায় চিঠি পাঠিয়ে। <a href="https://drive.google.com/file/d/1LejejcBRSkO5_OFMKbOACV0m1ZR6X_6d/view" className={styles.adminEmail}> 
              ফর্ম ডাউনলোড করতে এখানে ক্লিক করুন
              </a> </li>
</ul>
<span>
চিঠি পাঠানোর ঠিকানাঃ 
<br />
লুব্ধক'০৭ ফাউন্ডেশন (সৌরভ স্মৃতি বৃত্তি) 
<br />
প্রযত্নেঃ মুনিরুল আলম 
<br />
৩০২, আলিশান কমপ্লেক্স
<br />৩০০, এলিফ্যান্ট রোড
<br />ঢাকা-১২০৫  
</span>
<br />
<br />

<div className={styles.redText}>বৃত্তির আবেদনের শেষ সময় ২৮ ফেব্রুয়ারি, ২০২১। </div> 

<br />
<span>
পিডিএফ ফর্ম পূরণ করার সময় প্রয়োজনে অতিরিক্ত কাগজ ব্যবহার করা যাবে। বৃত্তি কেবলমাত্র পড়াশোনার প্রয়োজনীয় খরচে ব্যয় করা যাবে।
<br />
আবেদনকারীকে লুব্ধক'০৭ ফাউন্ডেশনের একজন সদস্যের রেফারেন্স দিতে হবে। যদি আবেদনকারী ফাউন্ডেশনের কোন সদস্যকে রেফারেন্সের জন্যে না পেয়ে থাকে, তাহলে ইমেইলের মাধ্যমে যোগাযোগ করতে অনুরোধ করা হচ্ছে। 
<br />
আবেদনের সকল তথ্য কেবল মাত্র আবেদনকারীর তথ্য যাচাই এর কাজে ব্যবহার করা হবে। প্রদানকৃত তথ্যে কোন ভুল থাকলে সেই আবেদন বিবেচনা করা হবে না। বৃত্তির বিষয়ে লুব্ধক'০৭ ফাউন্ডেশনের সিদ্ধান্তই চূড়ান্ত। 
  <br />বৃত্তি বিষয়ক যে কোন প্রয়োজনে যোগাযোগ করুন এই ঠিকানায়ঃ <a
                href="mailto:lubdhok.foundation@gmail.com"
                className={styles.adminEmail}
              >
                lubdhok.foundation@gmail.com
              </a> 
  
  </span>
  <span>
  <hr />
  <br />
  <br />
  আমাদের এই বৃত্তি পরিচালিত হচ্ছে পুরোপুরি স্বেচ্ছাশ্রমের উপর। বৃত্তির তহবিল আসছে আমাদের শুভানুধ্যায়ীদের অনুদান থেকে। আপনিও পারেন এই প্রকল্পে আমাদের পাশে থাকতে। আপনার অনুদান পাঠাতে পারেন নীচের যে কোন একটি উপায়েঃ
  <br /><br />
    <b>Bank Deposit / Online Transfer Info:</b><br />
    Bank Name: Sonali Bank Limited<br />
    Bank Branch: BUET<br />
    Account Name: Lubdhok’07 Foundation<br />
    Account number: 4404002000835<br />
    Routing No: 200270522<br />
    <br />
    <b>Paypal & Zelle:</b><br />
    Email: AHMED589@UMN.EDU<br />
     <br />
    <b>Bkash (Personal):</b><br />
    Mobile Number: 01816498063 (Md. Yousuf Sharif)<br />
    <br /> 
    অনুদানের সাথে অনুগ্রহ করে <b>সৌরভ স্মৃতি বৃত্তি</b> অথবা <b>Shourov Memorial Scholarship</b> উল্লেখ করবেন। 
    <br />
  </span>

  </span>
          </div>
        </div>
      </Layout>
    );
  }
}

export default scholarshipPage;
